<template>
    <div>
        <div class="main">
            <div class="title">
                用户中心
            </div>

            <div class="header">
                <img :src="user.avatar" alt="" v-if="is_login">
                <div class="user text_left" v-if="is_login">
                    <div class="user_detail">
                        用户名：{{user.nickname}}
                         <span style="margin-left: 10upx;" v-if="user.grade ==1">普通用户</span>
                         <span style="margin-left: 10upx;" v-if="user.grade ==2">月度会员</span> 
                         <span style="margin-left: 10upx;" v-if="user.grade ==3">年度会员</span>
                         <span v-if="user.grade == 2 ||user.grade == 3" style="margin-left: 10px;">{{user.viptime}}到期</span>
                    </div>
                    <div class="phone">
                        手机号：{{user.mobile}}
                    </div>
                </div>
                <div class="please_login" v-if="!is_login" @click="desc_login">
                  请登录
                </div>
                <img src="../assets/right.png" class="right_icon" alt="" srcset="" @click="jump('/edit')" v-if="is_login">
            </div>

            <div class="two">
                <div class="one" @click="jump('/vip')">
                    <img src="../assets/vip.png" alt="" srcset="">
                    会员中心
                </div>
                <div class="one" @click="jump('/chat')">
                    <img src="../assets/vip.png" alt="" srcset="">
                    资讯列表
                </div>
            </div>

            <div class="list_box">
                <div class="list_item" @click="jump('/order')">
                    <img src="../assets/date.png" alt="" srcset="">
                    订单管理
                </div>
                <div class="list_item" @click="jump('/record')">
                    <img src="../assets/date.png" alt="" srcset="">
                    接单记录
                </div>
                <div class="list_item" @click="jump('/edit','name')">
                    <img src="../assets/user.png" alt="" srcset="">
                    修改用户名
                </div>
                <div class="list_item" @click="jump('/edit','phone')">
                    <img src="../assets/edit.png" alt="" srcset="">
                    修改手机号
                </div>
                <div class="list_item" @click="cancel_user">
                    <img src="../assets/warning.png" alt="" srcset="">
                    账户注销
                </div>
            </div>
        </div>

        <div class="mask" v-show="show_mask" @click.self='show_mask = !show_mask'>
            <div class="mask_box">
                <img src="../assets/close.png" alt="" srcset="" class="close_img" @click="show_mask = !show_mask">
                <img src="../assets/warning.png" alt="" srcset="" class="warning_img">
                <div style="font-size:22px;font-weight:bold;margin-top:10px;">
                    确认注销用户！
                </div>
                <div class="button_box">
                    <div class="confirm" @click="cancel">
                        是
                    </div>
                    <div class="cancel" @click="cancel_cancel">
                        否
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            show_mask:false,
            user:'',
            is_login:false
        }
    },
    watch:{
        show_mask(val){
            if(val){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = ''
            }
        }
    },
    created(){
        this.loadDetail()
        var respone = this.getCode()
        if(respone.code){
            this.fullscreenLoading = true
            let data = {
                code:respone.code,
            }
           this.$api.post('user.oauth2_access_token',data,res =>{
              if(res.status == 200){
                  let data = {
                      openid:res.data.openid,
                      access_token:res.data.access_token
                  }
                  this.$api.post('user.oauth2_get_user_info',data,res =>{
                      if(res.data.status){
                          this.fullscreenLoading = false
                        if(typeof res.data.data.token =='undefined'){
                            this.$store.commit('set_wxid',res.data.data.user_wx_id)
                            this.$store.commit('change_resign',true)
                        }else{
                            this.$message({
                                type:'success',
                                message:'登陆成功'
                            })
                            this.$local.set('token',res.data.data.token)
                            location.reload()
                        }
                      }else{
                        // this.$message.error(res.data.msg)
                      }
                  })
              }else{
                  this.$message.error(res.statusText)
              }
           })
        }
    },
    mounted(){
        
    },
    methods:{
        // 打开登陆窗口
        desc_login(){
            this.$store.commit('change_desclogin',true)
        },
        //获取超链接中的微信登陆回调传值code
        getCode(){
            var url = location.hash
            var theRequest = new Object()
            if (url.indexOf("?") != -1) {
                var w_index = url.indexOf("?")
                var str = url.substr(w_index)
                var new_str = str.substr(1)
                var strs = new_str.split("&")
                for(var i = 0; i < strs.length; i ++) {
                    theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
                }
            }
            return theRequest
        },
        //加载“我”的个人信息
        loadDetail(){
            this.$api.userpost('user.info',{},(res)=>{
					if(res.data.status){
						this.is_login = true
                        this.user=res.data.data
                        console.log(this.user)
                        this.$store.commit('change_login',true)
                        this.$local.set('userinfo',JSON.stringify(res.data.data) )
					}else{
                        // this.$message.error(res.data.msg)
                        this.$local.del('userinfo')
                        this.is_login = false
					}
			})
        },
        // 退出登录
        cancel(){
            this.user = ''
            this.is_login = false
            this.$local.clear()
            this.show_mask = false
            this.$store.commit('change_login',false)
            this.$message({
                message:'已退出登录',
                type:'success'
            })
            location.reload()
        },



        jump(item,quary){
            console.log(quary)
            if(this.is_login){
                if(quary){
                    this.$router.push({path:item,query:{quary}})
                }else{
                   this.$router.push({path:item}) 
                }
                
            }else{
                this.$message.error('请先登录')
            } 
        },
        cancel_user(){
            this.show_mask = true
            
        },
        cancel_cancel(){
            this.show_mask = false
        }
    }
}
</script>
<style lang="scss" scoped>
    .main{
        padding: 30px;
        margin: 40px auto;
        width: 1140px;
        height: 816px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
    .title{
       text-align: left;
       font-size: 26px; 
       color: #144591;
    }
    .please_login{
        font-size: 25px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header{
        position: relative;
        width: 1140px;
        height: 160px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        img{
            margin-left: 30px;
            height: 106px;
            width: 106px;
        }
        .user{
            margin-left: 20px;
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            height: 106px;
            .user_detail{
                font-weight: bold;
                font-size: 26px;
                color: #000;
            }
            .phone{
                font-size: 23px;
            }
        }
        .right_icon{
            position: absolute;
            height: 26px;
            width: 14px;
            right: 25px;
        }
    }

    .two{
        display: flex;
        justify-content: space-between;
        height: 100px;
        margin-top: 30px;
        .one{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 555px;
            height: 100px;
            background: #FFFFFF;
            opacity: 1;
            border-radius: 10px;
            text-align: center;
            font-size: 26px;
            color: #666;
            img{
                height: 34px;
                width: 40px;
                margin-right: 20px;
            }
        }
    }

    .list_box{
        margin-top: 30px;
        width: 1140px;
        height: 438px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list_item{
            font-size: 22px;
            display: flex;
            align-items: center;
            height: 80px;
            width: 1036px;
            border-bottom: 1px solid #E9E9E9;
            img{
                height: 42px;
                width: 42px;
                margin-right: 36px;
            }
        }
    }
    .mask_box{
        position: relative;
        width: 460px;
        height: 245px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
        .close_img{
            height: 30px;
            width: 30px;
            position: absolute;
            right: 22px;
            top: 22px;
        }
        .warning_img{
            height: 38px;
            width: 43px;
            margin-top: 45px;
        }
        .button_box{
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            margin-top: 40px;
            width: 220px;
            .confirm{
                width: 95px;
                height: 32px;
                background: linear-gradient(162deg, #3C7DE2 0%, #2163C7 100%);
                opacity: 1;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                color: #FFF;
            }
            .cancel{
                width: 95px;
                height: 32px;
                border: 1px solid #BBBBBB;
                opacity: 1;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                color: #BBB;
            }
        }
    }
</style>